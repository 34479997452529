<template>
  <div>
     <el-table
        stripe
        :data="tableData"
        height="calc(100vh - 360px)"
      >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="companyName" label="所属线路" width="80"></el-table-column>
      <el-table-column prop="cph" label="车辆"></el-table-column>
      <el-table-column prop="realityDriverName" label="刷卡司机"></el-table-column>
      <el-table-column prop="idcard" label="身份证号" width="180"></el-table-column>
      <el-table-column prop="timelog" label="登签时间"></el-table-column>
      <el-table-column prop="timeout" label="退签时间"></el-table-column>
      <el-table-column prop="workTime" label="工作时长"></el-table-column>
      <el-table-column prop="driverTime" label="运行时长"></el-table-column>
     </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default:()=>[]
    }
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="scss" scoped>
  
</style>
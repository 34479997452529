<template>
  <div class="time-sheet-driver">
    <div class="record-centent">
      <div class="form-area table-search">
        <el-form
          class="demo-form-inline"
          ref="form"
          :model="form"
          label-position="left"
          label-width="82px"
          :inline="true"
          :rules="rules"
        >
          <el-form-item label="开始时间：" prop="beginTime">
            <el-date-picker
              v-model="form.beginTime"
              type="date"
              placeholder="选择开始时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="date"
              placeholder="选择结束时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="所属线路：">
            <el-select v-model="form.companyIds" placeholder="请选择线路">
              <el-option
                v-for="item in busLineList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车辆：">
            <el-select
              clearable
              v-model="form.vehicleNoList"
              multiple
              collapse-tags
              filterable
              placeholder="请选择车辆"
            >
              <el-option
                v-for="item in vehicleOptions"
                :key="item.vehicleNo"
                :label="item.cph"
                :value="item.vehicleNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="alarm-btn">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
            <el-button size="small" type="primary" @click="reset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 日报月报 -->
      <el-tabs
        type="border-card"
        @tab-click="clickTab"
        v-model="requestObj.alarmSpecies"
        tab-position="top"
        class="alarm-tab"
      >
        <el-tab-pane
          v-for="(item, index) in tabOptions"
          :key="index"
          :label="item.dictValue"
          :name="item.dictCode"
        >
          <!-- 日报 -->
          <driverTable :tableData="dayRecord" v-if="index === 0"></driverTable>
          <!-- 月报 -->
          <monthTable v-else :tableMonth="monthRecord"></monthTable>
          <!-- 分页 -->
          <my-page
            ref="pagination"
            @sendInfo="currentPageChange"
            :pageTotal="total"
            class="pagination"
          ></my-page>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { queryAllBusLineList, getBusByline } from '@/api/lib/bus-api.js';
import { staticDayRecord, staticMonthRecord } from '@/common/utils/statictableData.js'
import pagination from '@/components/pagination/pagination.vue'
import driverTable from '../table/timeDriverTable'
import monthTable from '../table/monthtable'
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkLimitDate,
  formatDate,
  formatDict,
  getCurent
} from '@/common/utils/index'
export default {
  components: {
    driverTable,
    monthTable,
    'my-page': pagination,
  },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }
    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(value, this.form.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(this.form.beginTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
    }
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        system: "0", //对应系统
        beginTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyIds: [],//选择线路
        realityDriverList: [],//选择司机
        vehicleNoList: [],//选择车辆
      },
      total: 0,//总条数
      busLineList: [],//线路列表
      vehicleOptions: [],//车辆列表
      //日报
      dayRecord: [],
      //月报
      monthRecord: [],
      requestObj: {
        currentPage: 1,
        pageSize: 10,
        system: "0", //对应系统
        alarmSource: '',
        alarmSpecies: 'D0008'
      },
      tabOptions: [
        { dictCode: "D0008", dictValue: "日报", id: 165, parentCode: "BJZL", remark: null },
        { dictCode: "D0009", dictValue: "月报", id: 166, parentCode: "BJZL", remark: null },
      ],
      rules: {
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },
  created () {
    this.queryAllBusLine();//获取所有线路
    this.queryAllBusByline();//获取所有车辆
  },
  methods: {
    clickTab (tab, event) {
      this.requestObj.alarmSpecies = tab.name
      this.requestObj.currentPage = 1;
      if (tab.name === 'D0008') {
        this.total = staticDayRecord.length;
      } else {
        this.total = staticMonthRecord.length;
      }
    },
    reset () {

    },
    // 获取所有车辆
    queryAllBusByline () {
      getBusByline().then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    search () {
      this.form.currentPage = 1;
      this.total = staticDayRecord.length;
      let dayRecord = [...staticDayRecord];
      let monthRecord = [...staticMonthRecord];
      this.dayRecord = dayRecord.splice(0, 10);
      this.monthRecord = monthRecord.splice(0, 10);
    },

    //获取所有线路
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    },
    // 分页参数改变
    currentPageChange (val) {
      let page = val.currentPage
      this.form.currentPage = page;
      let dayRecord = [...staticDayRecord];
      let monthRecord = [...staticMonthRecord];
      if (this.requestObj.alarmSpecies === 'D0008') {
        this.dayRecord = dayRecord.splice(10 * (page - 1), 10);
      } else {
        this.monthRecord = monthRecord.splice(10 * (page - 1), 10);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.time-sheet-driver {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.time-sheet-driver {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>
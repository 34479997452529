<template>
  <div>
     <el-table
        stripe
        :data="tableMonth"
        height="calc(100vh - 315px)"
      >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="month" label="月份"></el-table-column>
      <el-table-column prop="companyName" label="所属线路"></el-table-column>
      <el-table-column prop="cph" label="车辆"></el-table-column>
      <el-table-column prop="realityDriverName" label="刷卡司机"></el-table-column>
      <el-table-column prop="idcard" label="身份证号" width="200"></el-table-column>
      <el-table-column prop="workdays" label="出勤天数"></el-table-column>
     </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableMonth: {
      type: Array,
      default:()=>[]
    }
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="scss" scoped>
  
</style>
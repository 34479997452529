const staticDayRecord = [
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    timelog:'2021-03-15 06:57:00',
    timeout:'2021-03-15 19:20:23',
    workTime:'12小时23分钟',
    driverTime:'0小时0分钟'
  },
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    timelog:'2021-03-03 07:05:40',
    timeout:'2021-03-03 19:12:39',
    workTime:'12小时6分钟',
    driverTime:'7小时0分钟'
  },
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    timelog:'2021-03-23 07:47:15',
    timeout:'2021-03-23 19:01:30',
    workTime:'11小时14分钟',
    driverTime:'3小时30分钟'
  },
  
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    timelog:'2021-03-19 06:46:26',
    timeout:'2021-03-19 19:04:53',
    workTime:'12小时18分钟',
    driverTime:'0小时0分钟'
  },
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    timelog:'2021-03-03 06:30:30',
    timeout:'2021-03-03 18:45:49',
    workTime:'12小时15分钟',
    driverTime:'5小时50分钟'
  },
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    timelog:'2021-03-06 06:59:07',
    timeout:'',
    workTime:'',
    driverTime:''
  },
  {
    companyName:'3路',
    cph:'川H19045',
    realityDriverName:'俞涛',
    idcard:'510802197010210051',
    timelog:'2021-03-05 06:12:50',
    timeout:'2021-03-05 18:55:52',
    workTime:'12小时43分钟',
    driverTime:'1小时10分钟'
  },
  {
    companyName:'3路',
    cph:'川H19045',
    realityDriverName:'俞涛',
    idcard:'510802196808240474',
    timelog:'2021-03-04 06:41:46',
    timeout:'',
    workTime:'',
    driverTime:''
  },
  {
    companyName:'3路',
    cph:'川H19045',
    realityDriverName:'俞涛',
    idcard:'510802197010210051',
    timelog:'2021-03-03 07:06:07',
    timeout:'2021-03-03 09:45:16',
    workTime:'2小时39分钟',
    driverTime:'1小时10分钟'
  },
  {
    companyName:'3路',
    cph:'川H19045',
    realityDriverName:'俞涛',
    idcard:'510802197010210051',
    timelog:'2021-03-02 16:09:44',
    timeout:'',
    workTime:'',
    driverTime:''
  },
  {
    companyName:'3路',
    cph:'川H19436',
    realityDriverName:'孙步军',
    idcard:'510802197103150836',
    timelog:'2021-03-05 06:14:08',
    timeout:'',
    workTime:'',
    driverTime:''
  },
  {
    companyName:'3路',
    cph:'川H19436',
    realityDriverName:'孙步军',
    idcard:'510802197103150836',
    timelog:'2021-03-04 06:08:43',
    timeout:'2021-03-04 06:14:53',
    workTime:'0小时6分钟',
    driverTime:'0小时0分钟'
  },
  ]
const staticMonthRecord = [
  {
    companyName:'3路',
    cph:'川H19444',
    realityDriverName:'韩广平',
    idcard:'510802196808240474',
    month:'2021-03',
    workdays:5,
  },
  {
    companyName:'3路',
    cph:'川H19453',
    realityDriverName:'陈燕',
    idcard:'51080219680824000X',
    month:'2021-03',
    workdays:2,
  },
  {
    companyName:'3路',
    cph:'川H19045',
    realityDriverName:'俞涛',
    idcard:'510802197010210051',
    month:'2021-03',
    workdays:4,
  },
  {
    companyName:'3路',
    cph:'川H19436',
    realityDriverName:'孙步军',
    idcard:'510802197103150836',
    month:'2021-03',
    workdays:22,
  },
  {
    companyName:'3路',
    cph:'川H19435',
    realityDriverName:'鄂长成',
    idcard:'510802196808240474',
    month:'2021-03',
    workdays:1,
  },
  {
    companyName:'3路',
    cph:'川H18681',
    realityDriverName:'赵开明',
    idcard:'510802196808248218',
    month:'2021-03',
    workdays:5,
  },
  {
    companyName:'3路',
    cph:'川H19624',
    realityDriverName:'凡刚',
    idcard:'510802196808248218',
    month:'2021-03',
    workdays:10,
  },
  {
    companyName:'3路',
    cph:'川H19453',
    realityDriverName:'何军',
    idcard:'510802196808242014',
    month:'2021-03',
    workdays:10,
  },
  {
    companyName:'3路',
    cph:'川H19445',
    realityDriverName:'刘子兵',
    idcard:'510802196808244377',
    month:'2021-03',
    workdays:10,
  },
  {
    companyName:'3路',
    cph:'川H19484',
    realityDriverName:'鲁正芳',
    idcard:'510802196808248210',
    month:'2021-03',
    workdays:20,
  },
]
const accountDriver = [
  {
    busCode: "川H19045",
    busId: 157,
    attendance:12,
    attendanceTotal:24,
    completedTotalTimes: 5,
    done: {chartered: 0, energy: 0.5, normal: 4.5, other: 0, repairment: 0},
    driverId: 116,
    driverName: "俞涛",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 0,
    planTimes: 5,
    planTotalTimes: 5,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 0,
    seqId: 289,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0}
  },
  {
    busCode: "川H19457",
    busId: 156,
    attendance:14,
    attendanceTotal:26,
    completedTotalTimes: 5,
    done: {chartered: 0, energy: 0.5, normal: 4.5, other: 0, repairment: 0},
    driverId: 117,
    driverName: "叶仲文",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 0,
    planTimes: 5,
    planTotalTimes: 5,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 0,
    seqId: 290,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0},
  },
  {
    busCode: "川H19644",
    busId: 148,
    attendance:6,
    attendanceTotal:24,
    completedTotalTimes: 4,
    done: {chartered: 0, energy: 0, normal: 4, other: 0, repairment: 0},
    driverId: 118,
    driverName: "程志",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 169,
    operateRunTotalMile: 169,
    planTimes: 4,
    planTotalTimes: 4,
    recordTime: 1614819262347,
    runMile: 169,
    runTotalMile: 169,
    seqId: 291,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0},
  },
  {
    busCode: "川H19445",
    busId: 147,
    attendance:12,
    attendanceTotal:24,
    completedTotalTimes: 4,
    done: {chartered: 0, energy: 0, normal: 4, other: 0, repairment: 0},
    driverId: 121,
    driverName: "刘子兵",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 124,
    operateRunTotalMile: 124,
    planTimes: 4,
    planTotalTimes: 4,
    recordTime: 1614819262347,
    runMile: 124,
    runTotalMile: 124,
    seqId: 293,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0},
  },
  {
    busCode: "川H19437",
    busId: 154,
    attendance:12,
    attendanceTotal:24,
    completedTotalTimes: 5,
    done: {chartered: 0, energy: 0.5, normal: 4.5, other: 0, repairment: 0},
    driverId: 122,
    driverName: "母怀君",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 0,
    planTimes: 5,
    planTotalTimes: 5,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 0,
    seqId: 294,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0},
  },
  {
    busCode: "川H19445",
    busId: 147,
    attendance:13,
    attendanceTotal:24,
    completedTotalTimes: 0,
    done: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0},
    driverId: 123,
    driverName: "刘军",
    lackTotalTimes: 1,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 1,
    operateRunTotalMile: 1,
    planTimes: 1,
    planTotalTimes: 1,
    recordTime: 1614819262347,
    runMile: 1,
    runTotalMile: 1,
    seqId: 295,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0.5, normal: 0.5, other: 0, repairment: 0}
  },
  {
    busCode: "川H18681",
    busId: 145,
    attendance:9,
    attendanceTotal:24,
    completedTotalTimes: 4,
    done: {chartered: 0, energy: 0, normal: 3.5, other: 0, repairment: 0},
    driverId: 125,
    driverName: "赵开明",
    lackTotalTimes: 0.5,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 1,
    planTimes: 3.5,
    planTotalTimes: 4.5,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 1,
    seqId: 297,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0}
  },
  {
    busCode: "川H19444",
    busId: 149,
    attendance:12,
    attendanceTotal:24,
    completedTotalTimes: 4,
    done: {chartered: 0, energy: 0, normal: 0.5, other: 0, repairment: 0},
    driverId: 125,
    driverName: "赵开明",
    lackTotalTimes: 0.5,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 1,
    operateRunTotalMile: 1,
    planTimes: 0.5,
    planTotalTimes: 4.5,
    recordTime: 1614819262347,
    runMile: 1,
    runTotalMile: 1,
    seqId: 298,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0.5, normal: 0, other: 0, repairment: 0}
  },
  {
    busCode: "川H19444",
    busId: 149,
    attendance:12,
    attendanceTotal:25,
    completedTotalTimes: 9,
    done: {chartered: 0, energy: 0, normal: 4, other: 0, repairment: 0},
    driverId: 127,
    driverName: "韩广平",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 148,
    operateRunTotalMile: 148,
    planTimes: 4,
    planTotalTimes: 9,
    recordTime: 1614819262347,
    runMile: 148,
    runTotalMile: 148,
    seqId: 300,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0}
  },
  {
    busCode: "川H16764",
    busId: 152,
    attendance:10,
    attendanceTotal:24,
    completedTotalTimes: 9,
    done: {chartered: 0, energy: 0.5, normal: 4.5, other: 0, repairment: 0},
    driverId: 127,
    driverName: "韩广平",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 148,
    planTimes: 5,
    planTotalTimes: 9,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 148,
    seqId: 301,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0}
  },
  {
    busCode: "川H19417",
    busId: 150,
    attendance:11,
    attendanceTotal:20,
    completedTotalTimes: 5,
    done: {chartered: 0, energy: 0.5, normal: 4.5, other: 0, repairment: 0},
    driverId: 128,
    driverName: "孙贵林",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 0,
    planTimes: 5,
    planTotalTimes: 5,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 0,
    seqId: 284,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0}
  },
  {
    busCode: "川H19484",
    busId: 158,
    attendance:12,
    attendanceTotal:24,
    completedTotalTimes: 5,
    done: {chartered: 0, energy: 0.5, normal: 4.5, other: 0, repairment: 0},
    driverId: 130,
    driverName: "鲁正芳",
    lackTotalTimes: 0,
    lineId: 20,
    lineName: "13路",
    notOperateRunMile: 0,
    notOperateRunTotalMile: 0,
    oilConsumption: 0,
    oilConsumptionTotal: 0,
    operateRunMile: 0,
    operateRunTotalMile: 0,
    planTimes: 5,
    planTotalTimes: 5,
    recordTime: 1614819262347,
    runMile: 0,
    runTotalMile: 0,
    seqId: 285,
    settlementTime: "2021-03-03",
    settlementType: 0,
    type: 1,
    unfinished: {chartered: 0, energy: 0, normal: 0, other: 0, repairment: 0}
  }
]
export {
  staticDayRecord,
  staticMonthRecord,
  accountDriver,
}